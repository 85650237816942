@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Inter", sans-serif !important;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f4f7;
}

a {
  text-decoration: none !important;
  color: "primary" !important;
}

img {
  object-fit: fill;
}

button {
  text-transform: none !important;
}

span {
  white-space: nowrap;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}

::-webkit-scrollbar-button {
  background: #aaa;
}

::-webkit-scrollbar-track-piece {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  background: #aaa;
}
